import { Injectable, Inject, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject, of } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { JwtdecodeService } from 'src/app/services/jwtdecode.service';
import { LocalStorageService } from './localStorage.service';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)

  constructor(
    private jwtDecode: JwtdecodeService,
    private localStorageService: LocalStorageService,
    private injector: Injector,
    private authService: AuthService) {

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log("interceptor init");
    if (this.localStorageService.get('token')) {
      request = this.addToken(request, this.localStorageService.get('token'));
    }
    //console.log(this.localStorageService.get('token'));
    request = this.addGeneralParams(request);

    return next.handle(request).pipe(catchError(error => {
      const requestLog: any = {
        url: request.url,
        headers: error.headers,
      }
      if (error.status) {
        //insights.logExceptionHTTP(error.status + " HTTP Error: " + JSON.stringify(error.error), requestLog);
      } else {
        //insights.logException(JSON.stringify(error));
      }

      if (error instanceof HttpErrorResponse && error.status === 401) {
        return this.handle401Error(request, next);
      } else {
        return throwError(error);
      }
    }));
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private addGeneralParams(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        'Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'Module': 'ClientUI'
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);


      return this.authService.refreshToken().pipe(
        switchMap((token: any) => {
          this.localStorageService.set('token', token.token);
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.token);
          return next.handle(this.addToken(request, token.token));
        }),
        catchError(err => {
          const requestLog: any = {
            url: request.url,
            headers: err.headers
          }
          console.log('Handling error locally and rethrowing it...', err);

          if (err.status !== 400) {
            this.authService.logoutUser();
          }
          return throwError(err);
        }
        ));

    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}