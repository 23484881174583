import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtdecodeService } from 'src/app/services/jwtdecode.service';

@Injectable()
export class AuthGuard  {
  constructor(private router: Router, private jwtDecodeService: JwtdecodeService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (sessionStorage.getItem('ttr') && localStorage.getItem("token")) {
      // logged in so return true
      if (this.jwtDecodeService.isAdmin() || this.jwtDecodeService.isSuperAdmin() || this.jwtDecodeService.isUserTypeEqualsUser())
        return true;
      // if (this.jwtDecodeService.isUserTypeEqualsUser() && route.data.role === 'Member') {
      //   console.log(route.data)
      //   this.router.navigate(['/general/profile']);
      //   return false;
      // }
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } });
    return false;
  }
}


@Injectable({
  providedIn: 'root'
})
export class ChildActivateGuard  {

  constructor(private router: Router, private jwtDecodeService: JwtdecodeService) { }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {

    const token = localStorage.getItem('token');
    if (token) {
      const user = this.jwtDecodeService.getUserDetails();
      if (user.Role == 'Member' && state.url != '/general/profile') {
        this.router.navigate(['/general/profile']);
        return true;
      }
    }
    return true;
  }
}
