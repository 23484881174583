<nav class="navbar" style="width: 100%;">
  <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)">
    <i class="feather icon-menu"></i>
  </a>
  <div class="navbar-content">
    <!--<form class="search-form">
       <div class="input-group">
        <div class="input-group-text">
          <i class="feather icon-search"></i>
        </div>
        <input type="text" class="form-control" id="navbarForm" placeholder="Search here...">
      </div> 
    </form>-->
    <ul class="navbar-nav" style="position: relative; right: 230px;">
      <li class="nav-item nav-profile" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="profileDropdown">
          <img class="wd-30 ht-30 rounded-circle" [src]="profilePic" alt="profile">
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">
          <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
            <div class="mb-3">
              <img class="wd-80 ht-80 rounded-circle" [src]="profilePic" alt="">
            </div>
            <div class="text-center">
              <p class="tx-16 fw-bolder">{{name}}</p>
              <p class="tx-12 text-muted">{{email}}</p>
            </div>
          </div>
          <ul class="list-unstyled p-1">
            <li class="dropdown-item py-2">
              <a routerLink="/general/profile" class="d-flex text-body ms-0">
                <i class="feather icon-user me-2 icon-md"></i>
                <span>Perfil</span>
              </a>
            </li>
            <li class="dropdown-item py-2">
              <a href="" (click)="onLogout($event)" class="d-flex text-body ms-0">
                <i class="feather icon-log-out me-2 icon-md"></i>
                <span>Cerrar Sesión</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</nav>