import { Component, OnInit } from '@angular/core';
import { ignoreElements } from 'rxjs';
import { LocalStorageService } from './services/localStorage.service';
import { Router } from '@angular/router';
import { JwtdecodeService } from './services/jwtdecode.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Admin SMEO';

  constructor(
    private localStorage : LocalStorageService,
    private router: Router,
    private jwtService : JwtdecodeService 
  ){  } 

  ngOnInit(): void {
    const token = this.localStorage.get('token');
    if(token == null || token == undefined){
      this.router.navigate(['/auth/login']);
    }else{
      if(this.jwtService.isTokenExpired()){
        this.localStorage.clear();
        this.router.navigate(['/auth/login']);        
      }
    }
  }


}
