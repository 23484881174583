import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
  {
    label: 'Home',
    isTitle: true
  },
  {
    label: 'Dashboard',
    icon: 'home',
    link: '/dashboard'
  },
  {
    label: 'Socios',
    isTitle: true
  },
  {
    label: 'Socios Registrados',
    icon: 'users',
    link: '/members/users',
  },
  {
    label: 'Layout Socios',
    icon: 'check-circle',
    link: '/members/users/layout',
  },
  {
    label: 'Patrocinadores',
    icon: 'briefcase',
    subItems: [
      {
        label: 'Lista de Patrocinadores',
        link: '/partners/sponsors',
      },
      {
        label: 'Subir Layout',
        link: '/partners/sponsors/layout'
      }
    ]
  },
  {
    label: 'Anualidades',
    icon: 'refresh-ccw',
    link: '/annunities/list',
  },
  {
    label: 'Eventos',
    isTitle: true
  },
  {
    label: 'Asistentes',
    icon: 'user-check',
    link: '/congress/attendee',
  },
  {
    label: 'Calendario',
    icon: 'calendar',
    link: '/events/calendar',
  },
  {
    label: 'Facturación',
    isTitle: true
  },
  {
    label: 'Facturas Emitidas',
    icon: 'dollar-sign',
    link: '/invoice/list',
  },
  {
    label: 'Configuración',
    icon: 'settings',
    link: '/dashboard',
  },
  {
    label: 'Icons',
    icon: 'smile',
    subItems: [
      {
        label: 'Feather icons',
        link: '/icons/feather-icons',
      },
      {
        label: 'Mdi icons',
        link: '/icons/mdi-icons',
      }
    ]
  },

];

export const MENU_MEMBERS: MenuItem[] = [

  {
    label: 'Mi Cuenta',
    icon: 'home',
    link: '/general/profile'
  }];
