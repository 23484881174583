import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard, ChildActivateGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';


const routes: Routes = [
  { path: 'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    canActivateChild: [ChildActivateGuard],
    children: [
      {
        path: 'dashboard',
        data: {
          role: 'SuperAdmin'
        },
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'partners',
        data: {
          role: 'SuperAdmin'
        },
        loadChildren: () => import('./views/pages/smeo-partners/smeo-partners.module').then(m => m.SMEOPartnersModule)
      },
      {
        path: 'congress',
        data: {
          role: 'SuperAdmin'
        },
        loadChildren: () => import('./views/pages/congress/congress.module').then(m => m.CongressModule)
      },
      {
        path: 'members',
        data: {
          role: 'SuperAdmin'
        },
        loadChildren: () => import('./views/pages/members/members.module').then(m => m.MembersModule)
      },
      {
        path: 'invoice',
        data: {
          role: 'SuperAdmin'
        },
        loadChildren: () => import('./views/pages/invoice/invoice.module').then(m => m.InvoiceModule)
      },
      {
        path: 'annuities',
        data: {
          role: 'SuperAdmin'
        },
        loadChildren: () => import('./views/pages/annuity/annuity.module').then(m => m.AnnuityModule)
      },
      {
        path: 'events',
        data: {
          role: 'SuperAdmin'
        },
        loadChildren: () => import('./views/pages/events/events.module').then(m => m.EventsModule)
      },
      {
        path: 'icons',
        data: {
          role: 'SuperAdmin'
        },
        loadChildren: () => import('./views/pages/icons/icons.module').then(m => m.IconsModule)
      },
      {
        path: 'general',
        data: {
          role: 'Member'
        },
        loadChildren: () => import('./views/pages/general/general.module').then(m => m.GeneralModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'No se encontró la pagina',
      'desc': 'Oopps!! La pagina que buscas no existe.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
